import React, { Component } from 'react';
import InspectionTable from '../../app/tables/InspectionTable.jsx';

class ReportByDateAndDni extends Component {

  constructor(){
    super()
    this.friendlyPath = "inspecciones"
    this.path = process.env.REACT_APP_REPORT_DATEANDDNI
    // this.pathItem = process.env.REACT_APP_REPORT_DATEANDDNI_ITEM
    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        isForTable: true,
        isFilter: false,
        inputType: 'number',
      },
      {
        dataField: 'dni',
        text: 'DNI',
        isForTable: true,
        isFilter: true,
        inputType: 'number',
        min: 1,
        max: 999999999,
      },
      {
        dataField: 'inspector.fullname',
        text: 'Nombre',
        isForTable: true,
        isFilter: false,
        inputType: 'number',
        min: 1,
        max: 999999999,
      },
      {
        dataField: 'cardInfo.cardNumber',
        text: 'Tarjeta',
        isForTable: true,
        inputType: 'number',
        isFilter: false,
      },
      {
        dataField: 'fromDate',
        text: 'Fecha Desde',
        inputType: 'date',
        isFilter: true,
      },
      {
        dataField: 'toDate',
        text: 'Fecha Hasta',
        inputType: 'date',
        isFilter: true,
      },
      {
        dataField: 'inspectionDate',
        text: 'Fecha inspección',
        isForTable: true,
        inputType: 'text',
        formatter: function(cell,row,rowIndex,formatExtraData) {
          return row.inspectionDate.substring(0, 10) + " " + row.inspectionDate.substring(11, 19)
        }
      },
      {
        dataField: 'result',
        text: 'Resultado',
        isForTable: true,
        inputType: 'text',
        isFilter: false,
      }
    ]
  }

  render = () => 
    <InspectionTable 
      columns={this.columns} 
      path={this.path}
      friendlyPath={this.friendlyPath}
      hasCreate={false}
      hasDownload={true}
      history={this.props.history}
      showTableInit={false}
    />
      
}
export default ReportByDateAndDni;