import React, { Component } from 'react'
import Selector from '../../app/basic-ui/Selector'
import InputNumberBasic from '../../components/inputs/InputNumberBasic.js'
import InputTextBasic from '../../components/inputs/InputTextBasic.js'
import InputCheckboxBasic from '../../components/inputs/InputCheckboxBasic.js'
import InputDropdownBasic from '../../components/inputs/InputDropdownBasic.js'
import InputDateBasic from '../../components/inputs/InputDateBasic'
import { Link } from 'react-router-dom'
import ApiService from '../../services/ApiService'
import { saveAs } from 'file-saver'
import UserProfile from '../shared/UserProfile'

class Filter extends Component{

	constructor(props){
		super(props)
		this.userProfile = UserProfile.getInstance()
		this.errorMessage = props.errorMessage
		this.filters = props.filters
		this.request = new Map()
		this.hasDownload = props.hasDownload
		this.exportPath = props.exportPath
		this.onSend = props.onSend

		this.state = {
			showMessage : false,
			exportPushed : false,
			exportPathValues : new Map() 
		}
	}

	handleSubmit = async ( e ) => {
		e.preventDefault()
		const { exportPushed } = this.state
		
		let values = this.pickUpValues( e )		
		let oneInputFilled

		values.forEach( value => { if(value) oneInputFilled = true} )
		
		if(!oneInputFilled) 
			for(let i = 0 ; i < this.filters.length ; i++)

		e.target[i].required = 'required'
		
		if( e.target.reportValidity() ) {

			for( let i = 0 ; i < this.filters.length ; i++ ){
				this.request.set( e.target[i].name , e.target[i].value )
			}

			this.request.delete(undefined)
			this.setState({ exportPathValues : this.request })
			
			if( exportPushed ){

				this.setState({ exportPushed : false })
				let query = this.generateRequestPath( this.request )

				ApiService.getAxios().get(query).then( response => {
					const blob = new Blob([response.data], {type: response.headers['content-type']})
					saveAs( blob, this.generateFileName())
				})

			}else{
				this.onSend( this.request )
				this.request.clear()
			}

		} else{
			this.setState( {showMessage:true} )
			setTimeout(this.removeRequired, 5000, oneInputFilled, e.target)
		}

	}

	generateFileName = () => {
		const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
		return 'Inspecciones ' + new Date().toLocaleDateString('es-AR', options) + '.csv'
	}

	generateRequestPath = ( request ) => {
		let query = this.exportPath + '?'
		
		let keys = request.keys()
		
		for (let key of keys)
			query += `${key}=${request.get( key )}&` 
		
		query = query.slice( 0, query.length -1 )
		return query
	}

	pickUpValues = ( e ) => {
		this.setState( {
			showMessage : false,
			hasBeenSend : true
		} )

		let values = []
		
		for( let i = 0 ; i < this.filters.length ; i++ ){
			const INPUT = e.target[i]
			values.push( INPUT.value )
			if( INPUT.type === 'date' ){
				if( INPUT.value ){
					for( let j = 0 ; j < this.filters.length ; j++ ){
						let aux = e.target[j]
						if( aux.type === 'date' ){
							aux.required = 'required'
						}
					}
				}/*
				Acá se puede poner un ELSE para cuando la persona borra los datos del input
				*/
			} 
		}
		return values
	}

	removeRequired = ( oneInputFilled, target ) =>{
		if( !oneInputFilled )
			for(let i = 0 ; i < this.filters.length ; i++)
				target[i].required = ''
	}

	putValueOnRequestMap = (name, value) => {
		if(typeof value === 'string' && value.includes('-')){
			value = value.substring(0,value.indexOf('-'))
		} else if(value === null)
				name = null
		this.request.set(name, value)
	}

	drawInput = (filter, idx) => {
			if(filter.path && filter.label){
					return (
							<div className="col-lg">
									<label htmlFor={this.props.name}>
											{filter.label}
									</label>
									<Selector
											id = {idx} 
											path = {filter.path}
											text = {filter.label}
											placeholder = {`Seleccione ${filter.label}`} 
											property = {filter.dataField} 
											isForFilter = {true}
											getValueForFilter = {this.putValueOnRequestMap}
									>
									</Selector>
							</div>
					)
			}else{
					return  <div className="col-lg" key = {idx}>
										<label htmlFor={this.props.name}>{filter.label}</label>
										{this.createInput(filter, idx)}
									</div>
			}
	}

	createInput = ( element, idx ) => {
		switch(element.inputType) {
			case 'number':
				return <InputNumberBasic idx={idx} element={element} handleChange={null} />
			case 'text':
				return <InputTextBasic idx={idx} element={element} handleChange={this.handleChange} />
			case 'checkbox':
				return <InputCheckboxBasic idx={idx} element={element} handleChange={null} />
			case 'dropdown':
				return <InputDropdownBasic idx={idx} element={element} handleChange={this.handleChange} />
			case 'date':
				return <InputDateBasic idx={idx} element={element} handleChange={this.handleChange} />
			default:
				return 'No se encontró el filtro adecuado.'
		}
	}

	drawErrorMessage = () => this.errorMessage ? this.errorMessage : 'Debe completar los campos'

	render(){
		const buttonStyle = {
				height: '2.8rem',
				marginTop: '2rem'
		}

		return(
			<form onSubmit={this.handleSubmit}>
				<div className="card">
					{
						this.state.showMessage &&
						<div className="alert alert-danger" role="alert">
							{this.drawErrorMessage()}
						</div>
					}
					<div className="card-body row">
						{this.filters.map(
								(filter, idx) => (
										this.drawInput(filter, idx)
								)
						)}


						<button type="submit" className="col-lg-auto btn btn-outline-primary ml-3 mr-3" style={buttonStyle}>
								<i className="mdi mdi-magnify mr-1"></i>
								Buscar
						</button>

						{ 
							(this.props.hasDownload && this.userProfile.hasInspectorReportPermisson() ) && 
							
							<div className="mb-5 pb-4">
								<button
									type="submit"
									className="col-lg-auto btn btn-outline-primary ml-3 mr-3"
									style={buttonStyle}
									onClick={() => this.setState({ exportPushed : true })}
								>
									<i className="fa fa-download"></i>
									Exportar
								</button>
							</div>
						}

						{ !this.errorMessage && this.state.hasBeenSend &&

							<Link 
								to={'/verificadores'}
							>
									<button
										className="col-lg-auto btn btn-secondary ml-3 mr-3" 
										type="button" 
										style={buttonStyle}
									>
										<i className="mdi mdi-close-outline mr-1"></i>
										Limpiar filtro
									</button>
							</Link>

						}

						{this.props.children}

					</div>
						
				</div>
			</form>
		)
	}
}
export default Filter