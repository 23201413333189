import React, { Component } from 'react'
import { Form } from 'react-bootstrap';

class InputNumberBasic extends Component {

  constructor(props){
    super(props)
    this.idx = props.idx;
    this.element = props.element
    this.handleChange = props.handleChange
  }

    render() {
        return <>
                <label  htmlFor={this.idx}>{this.element.detailedText != null ? this.element.detailedText : this.element.text}</label>
                <Form.Control 
                  onChange={this.handleChange} 
                  onBlur={this.handleChange} 
                  className="form-control" 
                  name={this.element.dataField} 
                  id={this.idx===0?'0':this.idx} 
                  type="number"
                  required={this.element.required} 
                  min={this.element.min ? this.element.min : 0}
                  max={this.element.max ? this.element.max : 9999999999}
                  defaultValue={this.element.value}
                  />
                {
                  this.element.hasError &&
                  <span className="text-danger">
                    {this.element.messageError}
                  </span>
                }
            </>
    }
}

export default InputNumberBasic;
